const components = {
  AppLayout: () => import("@/layouts/AppLayout"),
  BasicInput: () => import("@/components/Input/BasicInput"),
  BasicButton: () => import("@/components/Button/BasicButton"),
  Logo: () => import("@/components/Logo"),
  Alert: () => import("@/components/Alert"),
  StatCard: () => import("@/components/StatCard"),
  Datatable: () => import("@/components/Datatable/Index"),
  SelectBox: () => import("@/components/SelectBox"),
  BasicLoading: () => import("@/components/BasicLoading"),
  ItemSearchInput: () => import("@/components/Input/ItemSearchInput"),
  GameMessage: () => import("@/components/GameMessage"),
  SelectServerModal: () => import("@/components/SelectServerModal"),
};

export default {
  install(Vue) {
    Object.entries(components).forEach(([name, component]) =>
      Vue.component(name, component)
    );
  },
};
