import getRoute from "../../plugins/globalRoutes";

export default {
  path: "fusion",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Fusion/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("fusionList"),
    },
    {
      name: "all-fusion",
      path: getRoute("fusionList"),
      meta: {},
      components: {
        fusion: () => import("../../views/Home/views/Fusion/List/Index"),
      },
    },
  ],
};
