module.exports = (value) => {
  let item = {};
  item.Count1 = value.Count1;
  item.Count2 = value.Count2;
  item.Count3 = value.Count3;
  item.Count4 = value.Count4;
  item.Item1 = value.Item1?.id ?? value.Item1 ?? -1;
  item.Item2 = value.Item2?.id ?? value.Item2 ?? -1;
  item.Item3 = value.Item3?.id ?? value.Item3 ?? -1;
  item.Item4 = value.Item4?.id ?? value.Item4 ?? -1;
  item.FusionType = value.FusionType;
  item.Reward = value.Reward?.id ?? value.Reward ?? -1;
  item.FusionRate = value.FusionRate;
  item.RewardValidDate = value.RewardValidDate;
  return item;
};
