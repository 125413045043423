module.exports = (value) => {
  let item = JSON.parse(JSON.stringify(value));
  let items = [];
  for (const [key, element] of Object.entries(item.items)) {
    element.TemplateID = element.TemplateID?.id ?? element.TemplateID ?? -1;
    delete element.Name;
    delete element.Image
    items.push(element);
  }

  item.items = items;
  return item;
};
