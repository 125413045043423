import getRoute from "../../plugins/globalRoutes";

export default {
  path: "users",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Users/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("userManager"),
    },
    {
      name: "user/manager",
      path: getRoute("userManager"),
      meta: {},
      components: {
        user: () => import("../../views/Home/views/Users/List/Index"),
      },
    },
  ],
};
