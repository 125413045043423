import { apiService } from "../../services/api";

const state = {};

const mutations = {};

const actions = {
  async getItemList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "items",
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "search-items?query=" + payload.query,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getItemDetail({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "item-detail/" + payload.id,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getItemPic({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "item-pic/" + payload.TemplateID + "/" + payload.Pic,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "item/update/" + payload.data.TemplateID,
      { ...payload.data },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
