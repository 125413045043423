import getRoute from "../../plugins/globalRoutes";

export default {
  path: "npcs",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Npc/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("npcList"),
    },
    {
      name: "all-npc",
      path: getRoute("npcList"),
      meta: {},
      components: {
        npc: () => import("../../views/Home/views/Npc/List/Index"),
      },
    },
  ],
};
