import Vue from "vue";
import Vuex from "vuex";

// begin::Modules
import auth from "./modules/auth";
import statistic  from "./modules/statistic"
import users from "./modules/users"
import drop from "./modules/drop"
import shopGoods from "./modules/shopGoods"
import portal from "./modules/portal"
import events from "./modules/events"
import server from "./modules/server"
import npc from "./modules/npc"
import pve from "./modules/pve"
import title from "./modules/title"
import fusion from "./modules/fusion"
import quest from "./modules/quest"

// end::Modules

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    statistic,
    users,
    drop,
    shopGoods,
    portal,
    events,
    server,
    npc,
    pve,
    title,
    fusion,
    quest
  },
});
