import axios from "axios";
import store from "@/store";

const axiosClient = axios.create();

axiosClient.interceptors.request.use((config) => {
  const { accessToken } = store.state.auth;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

export { axiosClient };
