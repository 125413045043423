import { apiService } from "../../services/api";

const state = {};

const mutations = {};

const actions = {
  async getUsers({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "users?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchUserPaginate({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "search-users/paginate",
      {
        name: payload.query,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserFight({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-fight/" + payload.userid,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserAvatarCollectionList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user/avatar-collection/" + payload.userid,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserAvatarCollectionItems({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user/avatar-collection-items/" + payload.userid + "/" + payload.avatarId,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserDropLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-drop/" + payload.userid + "?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserLostItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-lost-item/" + payload.userid + "?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserItems({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-items/" + payload.userid + "?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserMailLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-mail-log/" +
        payload.userid +
        "?page=" +
        payload.page +
        "&type=" +
        payload.type,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserShopLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "user-shop-log/" + payload.userid + "?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserAuctionLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      `user/auction/${payload.type}/${payload.userid}` +
        "?page=" +
        payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchAuctionLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      `user/auction/search/${payload.type}/${payload.userid}` +
        "?query=" +
        payload.query,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async changeUserPassword({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/change-password/" + payload.username,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateUserAvatarCollection({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "user/avatar-collection/" + payload.userid + "/" + payload.avatarId,
      {
        item: payload.item,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async sendUserCoin({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "user/send-coin",
      {
        data: payload.data,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteUserItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "delete-user-item/" + payload.UserID,
      {
        ItemID: payload.ItemID,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchUsers({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "search-users",
      {
        name: payload.query,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },

  async searchUserItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "search-user-items/" + payload.userid + "?query=" + payload.query,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },

  async searchUserMailLog({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "search-user-mail-log/" +
        payload.userid +
        "?query=" +
        payload.query +
        "&type=" +
        payload.type,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
