import { apiService } from "../../services/api";
import activeResource from "../resource/response/active";

const state = {};

const mutations = {};

const actions = {
  async getQuestTypeList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "quest/types/list",
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getQuestList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "quest/list/" + payload.type,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) {
          activeResource(response.data);
          payload.onSuccess(response);
        } 
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getQuestCondictionTypeList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "quest/condiction-types/list",
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getQuestCondictionList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "quest/condiction/" + payload.questID,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getQuestRewardList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "quest/reward/list/" + payload.questID,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateQuestDetail({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/detail/update",
      { ...payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateQuestCondictionDetail({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/condiction/update",
      { data: payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async createQuestCondiction({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/condiction/create/" + payload.questID,
      { data: payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async createQuest({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/create/" + payload.questType,
      { data: payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateQuestReward({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/reward/update",
      { data: payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
   async createQuestReward({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/reward/create",
      { data: payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteQuestReward({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/reward/delete/" + payload.ID,
      { },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteQuestCondiction({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "quest/condiction/delete",
      { ...payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchDropItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "search/drop-item/?query=" + payload.query + "&dropid=" + payload.dropid,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateDrop({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "update-drop/" + payload.id,
      { ...payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getDropItemList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "drop-item-list?drop_id=" + payload.drop_id + "&page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteDropItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "delete-drop-item/" + payload.drop_id,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
