import Vue from "vue";
import VueRouter from "vue-router";
import constant from "../plugins/globalVariables";
import globalRoutes from "../plugins/globalRoutes";
import drop from "./routes/drop";
import events from "./routes/events";
import users from "./routes/users";
import servers from "./routes/servers";
import npc from "./routes/npc";
import pve from "./routes/pve";
import title from "./routes/title";
import fusion from "./routes/fusion";
import log from "./routes/log";
import shopGood from "./routes/shopGood";
import quest from "./routes/quest";
import shopGoodBox from "./routes/shopGoodBox";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: globalRoutes("notFound"),
  },
  {
    name: "not-found",
    path: globalRoutes("notFound"),
    component: () => import("../views/NotFound/Index"),
  },
  {
    name: "select-server",
    path: globalRoutes("selectServer"),
    component: () => import("../views/SelectServer/Index"),
  },
  {
    name: "login",
    path: globalRoutes("login"),
    component: () => import("../views/Login/Index"),
  },
  {
    path: "",
    component: () => import("../views/Home/Index"),
    children: [
      {
        name: "home",
        path: globalRoutes("home"),
        components: {
          home: () => import("../views/Home/views/Dashboard/Index"),
        },
      },
     drop,
     npc,
     events,
     users,
     servers,
     pve,
     title,
     fusion,
     log,
     shopGood,
     quest,
     shopGoodBox
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: constant.BASE_URL,
  routes: routes,
});

const documentTitle = `${constant.APP_TITLE} | v${constant.APP_VERSION} (${constant.APP_DATE})`;
const authRoutes = ["login", "forgot-password"];

router.beforeEach((to, from, next) => {
  // Set Page Title
  document.title = to.meta.title || documentTitle;

  if (localStorage.getItem("accessToken")) {
    if (to.name == "login") {
      router.replace("/").catch(() => {});
    } else {
      next();
    }
  } else {
    if (!authRoutes.includes(to.name)) {
      router.replace({ name: "login" }).catch(() => {});
    } else {
      next();
    }
  }
});

export default router;
