import getRoute from "../../plugins/globalRoutes";

export default {
  path: "logs",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Log/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("logList"),
    },
    {
      name: "log-list",
      path: getRoute("logList"),
      meta: {},
      components: {
        log: () => import("../../views/Home/views/Log/List/Index"),
      },
    },
  ],
};
