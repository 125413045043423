import { apiService } from "../../services/api";

const state = {};

const mutations = {};

const actions = {
  async serverUsage({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "portal/server_usage",
      {},
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async sendUserProcess({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "portal/user_process",
      {
        data: payload.data
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async sendProcess({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "portal/" + payload.type,
      {
        data: payload.data
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
