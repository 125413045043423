import getRoute from "../../plugins/globalRoutes";

export default {
  path: "pves",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Pve/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("pveList"),
    },
    {
      name: "all-pve",
      path: getRoute("pveList"),
      meta: {},
      components: {
        pve: () => import("../../views/Home/views/Pve/List/Index"),
      },
    },
  ],
};
