import { apiService } from "../../services/api";

const state = {};

const mutations = {};

const actions = {
  async getPveList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "pve-list",
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async saveDropItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "save-drop-item",
      { ...payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchDropItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "search/drop-item/?query=" + payload.query + "&dropid=" + payload.dropid,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async updateDrop({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "update-drop/"+payload.id,
      { ...payload.form },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getDropItemList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "drop-item-list?drop_id=" + payload.drop_id + "&page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteDropItem({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "delete-drop-item/" + payload.drop_id,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
